<template>
    <div>
        <div class="display-block">
            <v-alert v-if="msg" :type="msgType">
                <ul v-for="item in msg" :key="item.errors">
                    <li>{{ item }}</li>
                </ul>
            </v-alert>
            <page-header v-model="search" title="Supplied Item" @input="updateApiCall(apiCall, search)"></page-header>
        </div>
        <div>
            <v-data-table :page="currentPage" :items-per-page="items.length" :headers="headers" :loading="loading"
                :items="sortedItems" class="elevation-1" :search="search" hide-default-footer>
                <template v-slot:item.status="{ item }">
                    <label
                        :class="`${['SOLD', 'PENDING', 'TRASHED'].indexOf(item.status) > -1 ? item.status : 'PENDING'}`">{{ item.status }}</label>
                </template>
                <template v-slot:item.action="{ item }" v-if="$vuetify.breakpoint.smAndUp">
                    <action-button title="View" key="View" icon="mdi-eye" :show="isAllowed('viewSuppliedItem')"
                        @click="$router.push('/app/suppliedItems/view/' + item._id)"></action-button>
                    <action-button title="Sold" key="Sold" icon="mdi-archive-check" v-if="item.status == 'PENDING'"
                        :show="isAllowed('viewSuppliedItem')" @click="updateSupplyItemStatus(item, 'SOLD')"></action-button>
                    <action-button title="Trashed" key="Trashed" icon="mdi-trash-can-outline" v-if="item.status == 'PENDING'"
                        :show="isAllowed('viewSuppliedItem')"
                        @click="updateSupplyItemStatus(item, 'TRASHED')"></action-button>
                </template>
                <template v-slot:item="{ item }" v-if="$vuetify.breakpoint.xsOnly">
                    <supply-card :item="item"></supply-card>
                </template>
            </v-data-table>
            <pagination :api="apiCall" ref="pagination" v-model="items" sort="-order.number,-status"></pagination>
        </div>
        <v-alert v-if="msg" :type="msgType">
            <ul v-for="item in msg" :key="item.errors">
                <li>{{ item }}</li>
            </ul>
        </v-alert>
    </div>
</template>

    
<script>
import ActionButton from '@/components/ActionButton'
import appConstants from '@/utils/appConstants'
import SupplyCard from '@/components/SuppliedItemResponsiveCard'
export default {
    components: {
        ActionButton,
        SupplyCard
    },
    data() {
        return {
            search: '',
            headers: [{
                text: 'Name',
                align: 'start',
                value: 'name',
            },
            {
                text: 'Order Number',
                align: 'start',
                value: 'order.number',
            },
            {
                text: 'Status',
                value: 'status'
            },
            {
                text: 'Price',
                value: 'price',
                align: 'right'
            },
            {
                text: "Action",
                value: "action",
                align: 'right'
            },
            ],
            items: [],
            loading: false,
            apiCall: appConstants.SUPPLIED_ITEM_API
        }
    },
    computed: {
        sortedItems() {
            return this.items.sort((a, b) => (a.order.number < b.order.number) ? 1 : (a.order.number > b.order.number) ? -1 : 0)
        }
    }
}
</script>

    
<style scoped></style>
