<template>
<v-layout class="bottom-border">
    <v-col class="align-card-font">
        <v-row>
            <label class="card-title-font">{{item.name}}</label>
        </v-row>
        <v-row>
            <label class="card-subtitle-font">{{item.order.number}}</label>
        </v-row>
    </v-col>
    <v-col>
        <label :class="`${['SOLD', 'PENDING', 'TRASHED'].indexOf(item.status)>-1 ? item.status: 'PENDING'}`">{{item.status}}</label>
    </v-col>
    <v-menu left>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item>
                <action-button key="View" icon="mdi-eye" :show="isAllowed('viewSuppliedItem')" @click="$router.push('/app/suppliedItems/view/'+item._id)"></action-button>
            </v-list-item>
            <v-list-item v-if="item.status=='PENDING'">
                <action-button key="Sold" icon="mdi-archive-check" :show="isAllowed('viewSuppliedItem')" @click="updateSupplyItemStatus(item,'SOLD')"></action-button>
            </v-list-item>
            <v-list-item v-if="item.status=='PENDING'">
                <action-button key="Trashed" icon="mdi-trash-can-outline" v-if="item.status=='PENDING'" :show="isAllowed('viewSuppliedItem')" @click="updateSupplyItemStatus(item,'TRASHED')"></action-button>
            </v-list-item>
        </v-list>
    </v-menu>
</v-layout>
</template>

    
<script>
import ActionButton from '@/components/ActionButton'
export default {
    components: {
        ActionButton,
    },
    props: {
        item: {
            type: Object
        },
    },
};
</script>

    
<style>
.bottom-border {
    border-bottom: 1px solid gray;
}
</style>
